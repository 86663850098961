var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"menu"}},[_c('div',{staticClass:"card-header",class:{ 'text-white bg-dark': _vm.isGuest }},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('span',{staticClass:"menu-link pointer",attrs:{"id":"title"},on:{"click":function($event){return _vm.fullReload()}}},[_vm._v("LessPass")])]),_c('div',{staticClass:"col-8 text-right"},[(_vm.saved && _vm.isAuthenticated)?_c('span',[_vm._m(0)]):_vm._e(),(
            !_vm.saved &&
              _vm.isAuthenticated &&
              _vm.$store.state.password.site !== '' &&
              _vm.$store.state.route.path === '/'
          )?_c('span',{staticClass:"menu-link",attrs:{"title":_vm.$t('Save')},on:{"click":function($event){return _vm.saveOrUpdatePassword()}}},[_c('i',{staticClass:"fa fa-lg fa-save pointer"})]):_vm._e(),(_vm.isAuthenticated)?_c('router-link',{staticClass:"menu-link pl-3",attrs:{"to":{ name: 'passwords' },"title":_vm.$t('Saved passwords')}},[_c('i',{staticClass:"fa fa-lg fa-key"})]):_vm._e(),_c('router-link',{staticClass:"menu-link pl-3",attrs:{"to":{ name: 'whatsnew' },"title":_vm.$t('What is new?')}},[_c('i',{staticClass:"fa fa-lg fa-info-circle"})]),_c('router-link',{staticClass:"menu-link pl-3",attrs:{"to":{ name: 'settings' },"title":_vm.$t('Settings')}},[_c('i',{staticClass:"fa fa-lg fa-cog"})]),(_vm.isAuthenticated)?_c('router-link',{staticClass:"menu-link pl-3",attrs:{"to":{ name: 'myaccount' },"title":_vm.$t('My Account')}},[_c('i',{staticClass:"fa fa-lg fa-user pointer"})]):_vm._e(),(_vm.isGuest)?_c('router-link',{staticClass:"menu-link pl-3",attrs:{"to":{ name: 'login' },"title":_vm.$t('Sign In')}},[_c('i',{staticClass:"fa fa-lg fa-sign-in pointer"})]):_vm._e()],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('small',[_c('i',{staticClass:"fa fa-lg fa-check pl-3"}),_vm._v(" saved")])
}]

export { render, staticRenderFns }